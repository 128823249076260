<template>
  <div class="purchase-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <PrintViewBar v-if="Purchase" :showEmailBtn="showEmailBtn" :id="Purchase.id" type="purchase"
          :title="`Purchase # ${this.Purchase.localId}`" :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name" :email="Purchase.Vendor.email" :obj="Purchase.Vendor"
          :text="`${currentBusiness.name} Purchase click here to view`" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <div v-if="!$vuetify.breakpoint.xsOnly">
              <img class="paidImg" v-if="Purchase.due === 0" width="300" src="../../../../assets/paidStamp.png" />
              <img class="paidImg" v-if="Purchase.deletedAt" width="300"
                src="../../../../assets/deletedStamp-removebg-preview.png" />
            </div>

            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  PURCHASE
                </div>
                <div class="font-weight-regular">
                  <div class="font-weight-medium">NOTE: <span class="font-weight-regular">{{ Purchase.note }}</span>
                  </div>
                  <div class="font-weight-medium">BILL TO</div>
                  <div class="font-weight-bold">
                    {{ Purchase.Vendor.name }}
                  </div>
                  <div>{{ Purchase.Vendor.address }}</div>
                  <div v-if="Purchase.Vendor.city">
                    {{ Purchase.Vendor.city }},<span class="text-uppercase">{{ Purchase.Vendor.state }}
                    </span>
                    {{ Purchase.Vendor.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img v-if="currentBusiness.imageUrl" width="130" class="logo mb-3" :src="currentBusiness.imageUrl"
                    alt="" />
                </div>
                <div v-if="!$vuetify.breakpoint.xsOnly" class="barcode mt-2">
                  <span
                    v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.barcodeOnPurchase">*{{
                      Purchase.localId }}*</span>
                </div>
                <div class="font-weight-regular">
                  <v-layout row>
                    <v-flex>
                      <div class="text-right">PURCHASE #</div>
                      <div class="text-right">DATE:</div>
                      <div class="text-right">CREATED BY:</div>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="text-left">{{ Purchase.localId }}</div>
                      <div class="text-left">
                        {{ Purchase.date | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-left" v-if="Purchase.SalesRep">
                        {{ Purchase.SalesRep.name }}
                      </div>
                      <div class="text-left" v-else>
                        {{ Purchase.User.name }}
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="pb-0" v-if="Purchase.Vendor.showBalance">
            <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <div class="headline font-weight-medium text-right blue--text">
                  BALANCE
                </div>
                <div class="display-2 font-weight-light text-right">
                  {{ Purchase.Vendor.balance | currency }}
                </div>
                <br />
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text>
                <v-data-table dense sort-by="id" :headers="headers" :itemsPerPage="Purchase.PurchaseDetails.length"
                  hide-default-footer :items="Purchase.PurchaseDetails">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">{{ item.name }}</td>
                      <!-- <td class="text-center"
                        v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
                        {{ item.boxQty }}</td> -->
                      <td class="text-center">{{ item.totalQty }}</td>
                      <td class="text-right">
                        <span>{{ item.cost | currency }}
                          <span v-if="showCost">/{{ item.cost | currency }}</span>
                        </span>
                      </td>
                      <td class="text-right">
                        {{ (item.cost * item.totalQty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <v-card-text class="pr-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span v-if="showEmailBtn" @click="showCost = !showCost" class="pointer font-weight-medium">
                  {{ totalQty }} ITEM(S)
                </span>
                <span v-else @click="showCost = !showCost" class="font-weight-medium">
                  {{ totalQty }} ITEM(S)
                </span>

                <div class="signature">
                  <v-divider></v-divider>
                  <div>Signature</div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">SUBTOTAL:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium text-right">
                      {{ Purchase.total | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout v-for="(payment, index) in Purchase.PaymentDetails" :key="index">
                  <!-- <v-flex >
                    <div class="text-right" v-if="currentBusiness.metadata && currentBusiness.metadata.hidePaymentDate">
                      {{ payment.createdAt | moment("MM/DD/YYYY") }}
                    </div>
                  </v-flex> -->
                  <v-flex>
                    <div class="text-right" v-if="
                      currentBusiness &&
                      currentBusiness.metadata &&
                      currentBusiness.metadata.hidePaymentDate
                    ">
                      {{ payment.createdAt | moment("LL") }}
                    </div>
                  </v-flex>
                  <v-flex xs5>
                    <div class="red--text text-right">
                      -{{ payment.amount | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">TOTAL PAID:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium red--text text-right">
                      -{{ totalPayment | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">PURCHASE DUE AMOUNT:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium text-right">
                      {{ Purchase.total - totalPayment | currency }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>

            <div class="my-5" v-if="currentBusiness &&
              currentBusiness.metadata &&
              currentBusiness.metadata.openPurchaseListOnPurchase
            ">
              <div v-if="OpenPurchases.length">
                <div class="title">Open Purchases</div>
                <OpenPurchaseList v-if="OpenPurchases" class="mt-3" :Purchase="OpenPurchases" />
              </div>
            </div>

            

            <div v-if="currentBusiness.policy">
              <div class="font-weight-medium">POLICY</div>
              <v-divider></v-divider>
              <div style="line-height: 12px">
                <small>{{ currentBusiness.policy }}</small>
              </div>
            </div>

            <v-col align="right">
              <img width="100" src="@/assets/images/poweredbylogo.png" />
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";
import OpenPurchaseList from "@/modules/Inventory/Purchase/components/OpenPurchaseList.vue";
import purchaseService from "@/modules/Inventory/Purchase/service.js";
import Hashids from "hashids";
import { mapGetters, mapActions } from "vuex";
const hashids = new Hashids();

export default {
  data() {
    return {
      totalPayment: 0,
      OpenPurchases: [],
      showCost: false,
      showEmailBtn: false,
      isLoading: true,
      Purchase: {
        PurchaseDetails: [],
      },
      headers: [],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  watch: {
    currentBusiness(val) {
      // console.log('val watch', val)
      this.headers.push(
        {
          text: "ITEM NAME",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: `${this.currentBusiness?.metadata?.boxCountOnInventory ? 'BOXES' : 'QTY'}`,
          align: "center",
          value: "qty",
          width: 50,
          sortable: false,
        },
        {
          text: "PRICE",
          align: "right",
          value: "rate",
          width: 100,
          sortable: false,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "total",
          width: 100,
          sortable: false,
        }
      )
    }
  },
  created() {
    console.log('currentBusiness', this.currentBusiness)
    if (this.$route.meta.public) {
      this.loadPublicData();
    } else {
      this.loadData();
      if (!this.currentBusiness) this.initUser();
    }



  },
  components: {
    PrintViewBar,
    OpenPurchaseList,
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;
      return purchaseService
        .getPurchaseByIdInPublic(id, { purchase: true, key: key })
        .then((response) => {
          // console.log("response purchase", response);
          this.initBusiness(response.business);
          this.$Progress.finish();
          this.Purchase = response.data;
          document.title = `Purchase # ${this.Purchase.localId}`;
          if (this.Purchase.PurchaseDetails) {
            this.Purchase.PurchaseDetails.map((row) => {
              this.totalQty += Math.abs(parseInt(row.totalQty));
            });
          }

          if (this.currentBusiness &&
            this.currentBusiness.metadata &&
            this.currentBusiness.metadata.openPurchaseListOnPurchase
          ) {
            this.getOpenPurchaseList(this.Purchase.VendorId);
          }

          this.isLoading = false;
        });
    },
    getOpenPurchaseList(vendorID) {
      // console.log('vendorID', vendorID)
      const where = {
        VendorId: vendorID,
        due: true,
      };
      // console.log("where", where);
      return purchaseService.getAll(where).then((response) => {
        // console.log("OpenPurchases", response);
        this.OpenPurchases = response.data;
        return response;
      });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);
      // console.log("purchase id", id);
      return purchaseService.getById(id, { purchase: true }).then((response) => {
        // console.log("response", response);
        this.Purchase = response.data;
        if (this.Purchase.Vendor.email) {
          this.showEmailBtn = true;
        }
        if (this.Purchase.PurchaseDetails) {
          this.Purchase.PurchaseDetails.map((row) => {
            this.totalQty += Math.abs(parseInt(row.totalQty));
            this.totalBoxes += Math.abs(parseInt(row.totalBoxes));
          });
        }

        if (this.Purchase.PurchasePaymentDetails) {
          this.Purchase.PurchasePaymentDetails.map((row) => {
            this.totalPayment += parseFloat(row.amount)
          });
        }


        if (
          this.currentBusiness &&
          this.currentBusiness.metadata &&
          this.currentBusiness.metadata.openPurchaseListOnPurchase
        ) {
          this.getOpenPurchaseList(this.Purchase.VendorId);
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
